import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStore = defineStore('app', () => {
  const updateAvailable = ref(false)
  const registration = ref<ServiceWorkerRegistration | undefined>()
  const lastUpdateCheck = ref<number>(0)
  const MIN_UPDATE_INTERVAL = 5 * 60 * 1000 // 5 minutes

  const initSWRegistration = async () => {
    if ('serviceWorker' in navigator) {
      const reg = await navigator.serviceWorker.getRegistration().catch((error) => {
        console.log('[APP STORE] initSWRegistration error:', error)
      })

      if (reg) {
        console.log(
          '[APP STORE] Existing service worker registration found',
          reg
        )
        registration.value = reg
      }

      return registration.value
    }
  }

  const immediateCheckForUpdates = async () => {
    // Prevent frequent checks
    const now = Date.now()
    if (now - lastUpdateCheck.value < MIN_UPDATE_INTERVAL) {
      console.log('[APP STORE] Skipping update check - too soon')
      return
    }

    lastUpdateCheck.value = now
    await initSWRegistration()

    if (!registration.value) {
      console.log('[APP STORE] No registration found')
      return
    }

    console.log('[APP STORE] Checking for updates')
    await registration.value.update()

    const hasUpdate = !!registration.value.waiting
    console.log('[APP STORE] Update available:', hasUpdate)
    return hasUpdate
  }

  return {
    updateAvailable,
    registration,
    lastUpdateCheck,
    initSWRegistration,
    immediateCheckForUpdates
  }
})

import { onMounted } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { useAppStore } from '@/stores/app'
import { logError } from '@/utils/error-logger'

export function useUpdateNotification() {
  const appStore = useAppStore()

  const intervalMS = import.meta.env?.VITE_CHECK_FRESH_VERSION_INTERVAL || 60 * 60 * 1000

  onMounted(appStore.initSWRegistration)

  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (r) {
        console.log('[SW] Initial registration', {
          url: swUrl,
          isProd: import.meta.env?.PROD,
          checkInterval: intervalMS,
          registration: r
        })
        appStore.registration = r
        setInterval(async () => {
          if (r.installing || !navigator) {
            console.log('[SW] Skip update check - worker installing or no navigator')
            return
          }

          if ('connection' in navigator && !navigator.onLine) {
            console.log('[SW] Skip update check - offline')
            return
          }

          try {
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache'
              }
            })

            if (resp?.status === 200) {
              console.log('[SW] Checking for updates...')
              await r.update()
              // Add check to prevent multiple updates
              if (r.waiting) {
                console.log('[SW] Update available but waiting')
              }
            }
          } catch (error) {
            console.error('[SW] Error checking for updates:', error)
          }
        }, intervalMS)
      }
    },
    onNeedRefresh() {
      console.log('[SW] onNeedRefresh called (PROD value=', import.meta.env?.PROD, ')')
      // Add debounce to prevent rapid updates
      if (import.meta.env?.PROD && !appStore.updateAvailable) {
        console.log('[SW] Setting update available')
        appStore.updateAvailable = true
      }
    }
  })

  const refreshApp = () => {
    const loc = window.location?.pathname || 'unknown page'
    logError('New content popup clicked', loc, {
      currentVersion: import.meta.env?.VITE_APP_VERSION || 'unknown'
    })
    updateServiceWorker(true)
    appStore.updateAvailable = false
  }

  const cancel = () => {
    appStore.updateAvailable = false
    offlineReady.value = false
    needRefresh.value = false
  }

  return {
    offlineReady,
    needRefresh,
    cancel,
    refreshApp
  }
}
